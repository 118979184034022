module.exports.FirebaseConfig = {
  locationId: "us-central",
  apiKey: "AIzaSyDDs9bmOnTMDqDEsHhUJlQdCDqw6O5rzug",
  authDomain: "medicaltaxi-fbd8f.firebaseapp.com",
  databaseURL: "https://medicaltaxi-fbd8f-default-rtdb.firebaseio.com",
  projectId: "medicaltaxi-fbd8f",
  storageBucket: "medicaltaxi-fbd8f.appspot.com",
  messagingSenderId: "947009304374",
  appId: "1:947009304374:web:4482e5b509831ac0326597",
  measurementId: "G-8ZSRQJH01M",
};
