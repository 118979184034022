import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";

const localizer = momentLocalizer(moment);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "3px solid #eee",
  boxShadow: 1,
  p: 4,
};
export default function Bookingcalendar() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setclickedBooking(event.id);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const columns = [
    { title: t("booking_id"), field: "id" },
    {
      title: t("booking_date"),
      field: "tripdate",
      render: (rowData) =>
        rowData.tripdate ? new Date(rowData.tripdate).toLocaleString() : null,
    },
    { title: t("car_type"), field: "carType" },
    { title: t("customer_name"), field: "customer_name" },
    { title: t("pickup_address"), field: "pickupAddress" },
    { title: t("drop_address"), field: "dropAddress" },
    { title: t("assign_driver"), field: "driver_name" },
    {
      title: t("booking_status"),
      field: "status",
      render: (rowData) => <span>{t(rowData.status)}</span>,
    },
    { title: t("cancellation_reason"), field: "reason" },
    {
      title: t("otp"),
      field: "otp",
      render: (rowData) =>
        rowData.status === "NEW" || rowData.status === "ACCEPTED" ? (
          <span>{rowData.otp}</span>
        ) : null,
    },
    { title: t("trip_cost"), field: "trip_cost" },
    { title: t("trip_start_time"), field: "trip_start_time" },
    { title: t("trip_end_time"), field: "trip_end_time" },
    { title: t("vehicle_no"), field: "vehicle_number" },
    { title: t("trip_cost_driver_share"), field: "driver_share" },
    { title: t("convenience_fee"), field: "convenience_fees" },
    { title: t("discount_ammount"), field: "discount" },
    { title: t("Customer_paid"), field: "customer_paid" },
    { title: t("payment_mode"), field: "payment_mode" },
    { title: t("payment_gateway"), field: "gateway" },
    { title: t("cash_payment_amount"), field: "cashPaymentAmount" },
    { title: t("card_payment_amount"), field: "cardPaymentAmount" },
    { title: t("wallet_payment_amount"), field: "usedWalletMoney" },
  ];
  const [data, setData] = useState([]);
  const [clickedBooking, setclickedBooking] = useState();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings, open]);

  const eventsdata = [];

  for (var key in bookinglistdata.bookings) {
    eventsdata.push({
      id: bookinglistdata.bookings[key]["id"],
      title:
        "# " +
        moment
          .unix(bookinglistdata.bookings[key]["bookingDate"])
          .format("HH:mm") +
        " " +
        bookinglistdata.bookings[key]["pickup"]["add"],
      allDay: false,
      start: bookinglistdata.bookings[key]["bookingDate"],
      end: bookinglistdata.bookings[key]["bookingDate"],

      end: bookinglistdata.bookings[key]["bookingDate"],
      end: bookinglistdata.bookings[key]["bookingDate"],
      end: bookinglistdata.bookings[key]["bookingDate"],
      end: bookinglistdata.bookings[key]["bookingDate"],
      pickup: bookinglistdata.bookings[key]["pickup"]["add"],
      drop: bookinglistdata.bookings[key]["drop"]["add"],
      bookingdate: moment.unix(bookinglistdata.bookings[key]["bookingDate"]),
      driver:
        bookinglistdata.bookings[key]["driver_name"] +
        " " +
        bookinglistdata.bookings[key]["driver_contact"],
      client:
        bookinglistdata.bookings[key]["customer_name"] +
        " " +
        bookinglistdata.bookings[key]["customer_email"] +
        " " +
        bookinglistdata.bookings[key]["customer_contact"],

      distance:
        bookinglistdata.bookings[key]["estimateDistance"] +
        " " +
        bookinglistdata.bookings[key]["estimateTime"],
      estimate: bookinglistdata.bookings[key]["estimate"],
    });
  }
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={eventsdata}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        onSelectEvent={handleOpen.bind(this)}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            BOOKING DETAILS
          </Typography>

          {clickedBooking && bookinglistdata.bookings ? (
            <>
              <p>Booking ID : {clickedBooking} </p>

              <p>Pickup : </p>

              <p>Destination : </p>

              <p>Date Time :</p>

              <p>Driver :</p>

              <p>Client :</p>

              <p>Trip :</p>

              <p>Total Price :</p>
            </>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
