import React, { useContext, useState } from "react";
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { FirebaseContext } from "common";
import { colors } from "../components/Theme/WebTheme";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import CarIcon from "@material-ui/icons/DirectionsCar";
import NotifyIcon from "@material-ui/icons/NotificationsActive";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AccountBalanceTwoToneIcon from "@material-ui/icons/AccountBalanceTwoTone";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import BarChartIcon from "@material-ui/icons/BarChart";
import ExitIcon from "@material-ui/icons/ExitToApp";

import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BlockIcon from "@material-ui/icons/Block";
import PaymentIcon from "@material-ui/icons/Payment";
import TuneIcon from "@material-ui/icons/Tune";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DateRangeIcon from "@material-ui/icons/DateRange";

function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const { signOut } = api;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingRight: theme.spacing(4),
    },
  }));

  const classes = useStyles();

  const [menuActive, setMenuActive] = useState([false, false, false, false]);

  const handleClick = (index) => {
    let temp = menuActive;
    temp[index] = !menuActive[index];
    setMenuActive(temp);
  };
  const arrowLeft = {
    // justifyContent:'flex-end',
    position: "absolute",
    left: 0,
  };
  const arrowRight = {
    // justifyContent:'flex-end',
    position: "absolute",
    right: 0,
  };

  let isAdmin =
    auth.info && auth.info.profile && auth.info.profile.usertype === "admin";

  let isFleetAdmin =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.usertype === "fleetadmin";

  let isDispatcher =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.usertype === "dispatcher";

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          backgroundColor: "#fff",
        }}
      >
        <img
          style={{
            marginLeft: "15px",
            marginTop: "10px",
            marginBottom: "10px",
            width: "160px",
          }}
          src={require("../assets/img/logo192x192.png").default}
          alt="Logo"
        />
      </div>
      <Divider />

      <MenuList>
        {isAdmin || isFleetAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <AccountBalanceTwoToneIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("dashboard_text")}</Typography>
          </MenuItem>
        ) : null}

        <MenuItem>Dispatch</MenuItem>

        <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t("bookings")}</Typography>
        </MenuItem>

        {isAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/addbookings">
            <ListItemIcon>
              <AddToPhotosIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("addbookinglable")}</Typography>
          </MenuItem>
        ) : null}

        {isAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/bookingcalendar">
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <Typography variant="inherit">Calendar</Typography>
          </MenuItem>
        ) : null}

        {isAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/cancelreasons">
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {t("cancellation_reasons")}
            </Typography>
          </MenuItem>
        ) : null}

        <MenuItem>Users</MenuItem>
        {isAdmin || isFleetAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/drivers">
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("drivers")}</Typography>
          </MenuItem>
        ) : null}

        {isAdmin || isDispatcher ? (
          <MenuItem component={Link} to="/riders">
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("riders")}</Typography>
          </MenuItem>
        ) : null}

        {isAdmin ? (
          <MenuItem component={Link} to="/dispatchers">
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <Typography variant="inherit">Dispatchers</Typography>
          </MenuItem>
        ) : null}

        {isAdmin ? (
          <>
            <MenuItem>Pricing</MenuItem>
            <MenuItem component={Link} to="/cartypes">
              <ListItemIcon>
                <CarIcon />
              </ListItemIcon>
              <Typography variant="inherit">{t("car_type")}</Typography>
            </MenuItem>
          </>
        ) : null}

        {isAdmin ? (
          <>
            <MenuItem>Reports</MenuItem>
            <MenuItem component={Link} to="/earningreports">
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <Typography variant="inherit">{t("earning_reports")}</Typography>
            </MenuItem>
          </>
        ) : null}

        {isAdmin || isFleetAdmin ? (
          <MenuItem component={Link} to="/driverearning">
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("driver_earning")}</Typography>
          </MenuItem>
        ) : null}

        {isAdmin ? (
          <>
            <MenuItem>Accounting</MenuItem>
            <MenuItem component={Link} to="/addtowallet">
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <Typography variant="inherit">{t("add_to_wallet")}</Typography>
            </MenuItem>
          </>
        ) : null}

        {isAdmin ? (
          <MenuItem component={Link} to="/withdraws">
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("withdraws")}</Typography>
          </MenuItem>
        ) : null}

        {isAdmin ? (
          <MenuItem component={Link} to="/promos">
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("promo")}</Typography>
          </MenuItem>
        ) : null}

        <MenuItem>Settings</MenuItem>
        {isAdmin ? (
          <MenuItem component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {t("push_notification_title")}
            </Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/settings">
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t("settings_title")}</Typography>
          </MenuItem>
        ) : null}

        <MenuItem>
          <Typography variant="inherit"></Typography>
        </MenuItem>
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t("profile")}</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t("logout")}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;
