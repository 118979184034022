import React, { useState, useEffect, useContext, useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import {
  Paper,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Modal,
  Grid,
  Typography,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import SectionDownload from "./Sections/SectionDownload.js";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { FirebaseContext } from "common";
import { colors } from "../components/Theme/WebTheme";
import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
}));

const icons = {
  paypal: require("../assets/img/payment-icons/paypal-logo.png").default,
  braintree: require("../assets/img/payment-icons/braintree-logo.png").default,
  stripe: require("../assets/img/payment-icons/stripe-logo.png").default,
  paytm: require("../assets/img/payment-icons/paytm-logo.png").default,
  payulatam: require("../assets/img/payment-icons/payulatam-logo.png").default,
  flutterwave: require("../assets/img/payment-icons/flutterwave-logo.png")
    .default,
  paystack: require("../assets/img/payment-icons/paystack-logo.png").default,
  securepay: require("../assets/img/payment-icons/securepay-logo.png").default,
  payfast: require("../assets/img/payment-icons/payfast-logo.png").default,
  liqpay: require("../assets/img/payment-icons/liqpay-logo.png").default,
  culqi: require("../assets/img/payment-icons/culqi-logo.png").default,
  mercadopago: require("../assets/img/payment-icons/mercadopago-logo.png")
    .default,
  test: require("../assets/img/payment-icons/test-logo.png").default,
};

export default function LandingPage(props) {
  const { api, appcat } = useContext(FirebaseContext);
  const { GetDateString } = api;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { ...rest } = props;
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const providers = useSelector((state) => state.paymentmethods.providers);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [role, setRole] = useState(null);
  const rootRef = useRef(null);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [drivers, setDrivers] = useState([]);

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    parcelTypeIndex: 0,
    optionIndex: 0,
    parcelTypeSelected: null,
    optionSelected: null,
  });

  const handleChange = (e) => {
    if (e.target.name === "parcelTypeIndex") {
      setInstructionData({
        ...instructionData,
        parcelTypeIndex: parseInt(e.target.value),
        parcelTypeSelected: selectedCarDetails.parcelTypes[e.target.value],
      });
    } else if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedCarDetails.options[e.target.value],
      });
    } else if (e.target.name === "selectedProviderIndex") {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    } else {
      setInstructionData({
        ...instructionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (userdata.users) {
      let arrDrivers = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (
          user.usertype &&
          user.usertype === "driver" &&
          user.approved === true &&
          user.queue === false &&
          user.driverActiveStatus === true &&
          user.location
        ) {
          arrDrivers.push({
            uid: user.id,
            location: user.location,
          });
        }
      }
      setDrivers(arrDrivers);
    }
  }, [userdata.users]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpg").default}>
        {(cartypes && !role) ||
        (cartypes &&
          (role === "rider" || role === "admin" || role === "dispatcher")) ? (
          <div
            className={classes.container}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1
                  style={{
                    textAlign: isRTL === "rtl" ? "right" : "left",
                    position: "relative",
                    marginTop: "30px",
                    minHeight: "32px",
                    color: "#FFFFFF",
                    textDecoration: "none",
                    fontSize: "2.955rem",
                  }}
                >
                  Medical Taxi panel
                </h1>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <br />
              <h1
                style={{
                  textAlign: isRTL === "rtl" ? "right" : "left",
                  position: "relative",
                  marginTop: "30px",
                  minHeight: "32px",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  fontSize: "2.955rem",
                }}
              >
                Medical Taxi panel
              </h1>
            </GridItem>
          </GridContainer>
        )}
      </Parallax>

      <Footer />

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
